import React, { Component } from 'react';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPaidInvoices } from '../../modules/invoice';
import { getPaidList } from '../../modules/payment';
import moment from 'moment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const Container = styled.div `
  padding: 40px;
`

const Loading = styled.div `

`

const NoNotes = styled.div `
	width: 100%;
	max-width: 1200px;
	text-align: center;
	margin: 0px auto;
	margin-top: 100px;
`

const Content = styled.div `

`

const Pay = styled.td `
	color: #0c6382;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: .2s all;
	opacity: .7;

	&:hover{
		opacity: 1;
	};
`

const Total = styled.div `

`
const DueDate = styled.td `
	color: #f29000;
`

const Pahada = styled.table `
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;
	margin-top: 40px;
	font-size: 12px;

	td{
		border: 1px solid;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	th{
		border: 1px solid white;
		background: black;
		color: white;
		font-weight: normal;
		line-height: 30px;
	}

`

const InvoiceList = styled.table `
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;
	margin-top: 40px;
	font-size: 12px;

	th{
		background: black;
		height: 40px;
		color: white;
		font-weight: normal;
		border: 1px solid white;
	}

	td{
		text-align: center;
		height: 40px;
		border: 1px solid #ccc;
	}
`

const TotalLabel = styled.div `
	font-size: 16px;
	opacity: .4;
`

const TotalAmount = styled.div `
	font-size: 24px;
	margin-top: 5px;
	color: red;
`

const Separator = styled.div `
	margin-top: 30px;
`

class UpcomingPayments extends Component {

	componentDidMount(){
		this.props.getPaidInvoices();
		this.props.getPaidList();
	}

	handleChangeTab(value){
		this.setState({
			active_tab: value
		})
	}

	state = {
		active_tab: 0
	}

	invoiceList(){
		var { invoices, total, account, payments } = this.props;

		var active_tab = this.state.active_tab;

		invoices = invoices.map((invoice) => {
			invoice.url = invoice.invoice_url;

			if(!invoice.invoice_url){
				if(invoice.attachment && invoice.attachment.uuid){
					invoice.url = `http://${window.location.hostname}:4000/resources/${invoice.attachment.uuid}`
				}
			}

			return invoice
		})


		return (
			<Content>
				<Total>
					<TotalLabel>
						Total Paid Amount
					</TotalLabel>
					<TotalAmount>
						Rs. {total}
					</TotalAmount>
				</Total>
				<Separator />
				<Tabs value={active_tab} onChange={(comp, value) => this.handleChangeTab(value)}>
					<Tab label="Invoices" />
					<Tab label="Payments" />
		        </Tabs>

				{active_tab === 0 && (
					<InvoiceList border="1">
						<thead>
							<tr>
								<th>
									Invoice Ref
								</th>
								<th>
									{account.is_seller ? 'Buyer' : 'Seller'}
								</th>
								<th>
									Amount
								</th>
								{!account.is_seller && (
								<th>
									Buyer PO Id
								</th>
								)}
								<th>
									PO ID
								</th>
								<th>
									Invoice Date
								</th>
								<th>
									Shipping Charge
								</th>
								<th>
									Payment Status
								</th>
							</tr>
						</thead>
						<tbody>
							{invoices.map((invoice) => (
								<tr key={invoice._id} >
									<td>
										<a href={invoice.url} target="_blank">
											{invoice.number}
										</a>
									</td>
									<td>
										{!account.is_seller ? invoice.po.seller.shop : invoice.po.customer.company}
									</td>
									<td>
										Rs. {invoice.amount}
									</td>
									{!account.is_seller && (
									<td>
										{invoice.buyer_po_id ? invoice.buyer_po_id : '-'}
									</td>
									)}
									<td>
										PO - {invoice.po.id_po}
									</td>
									<td>
										{moment(invoice.invoice_date).format('DD/MM/YYYY ')}
									</td>
									<td>
										Rs. {invoice.shipping_charge}
									</td>
									<td>
										{invoice.payment_status}
									</td>
								</tr>
							))} 
						</tbody>
					</InvoiceList>

				)}

				{active_tab === 1 && (
					<Pahada>
						<thead>
							<tr>
								<th> Payment Id </th>
								<th> Amount </th>
								{account.is_seller ? ( <th> Buyer </th> ) : ( <th> Seller </th> ) }
								<th> Time </th>
								<th> PO Ref </th>
								<th> Invoice Ref </th>
								<th> Method </th>
								<th> Type </th>
							</tr>
						</thead>
						<tbody>
							{payments.map((payment) => (
								<tr key={payment._id}>
									<td>
										{payment.id}
									</td>
									<td>
										Rs. {payment.amount.toLocaleString()}
									</td>
									<td>
										-
									</td>
									<td>
										{moment(payment.paid_at).format('DD/MM/YYYY hh:mm')}
									</td>
									<td>
										PO - {payment.po_ref}
									</td>
									<td>
										{payment.inv_ref}
									</td>
									<td>
										{payment.method_used}
									</td>
									<td>
										{payment.payment_type}
									</td>
								</tr>
							))}
						</tbody>
					</Pahada>
				)}
					
			</Content>
		)
	}

	render(){
		const {invoices, req} = this.props;
		return (
			<Container>
				{req && <Loading> Loading ... </Loading>}
				{!req && !!invoices.length && this.invoiceList()}
				{!req && !invoices.length && <NoNotes> You have no Overdue Payments to be made </NoNotes> }
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		req: state.invoice.paid_invoices.req,
		invoices: state.invoice.paid_invoices.invoices,
		total: state.invoice.paid_invoices.total_amount,
		account: state.user.account,
		payments: state.payment.paidList
	}
}


export default withRouter(connect(mapStateToProps, { getPaidInvoices, getPaidList })(UpcomingPayments))
