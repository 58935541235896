const axios = require('axios');

export const REQ_CREDIT_NOTES = 'REQ_CREDIT_NOTES'
export const REC_CREDIT_NOTES = 'REC_CREDIT_NOTES'

const initialState = {
  list: [],
  total: 0,
  reqCreditNotes: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQ_CREDIT_NOTES:
      return {
        ...state,
        reqCreditNotes: true
      }
    case REC_CREDIT_NOTES:
      return {
        ...state,
        reqCreditNotes: false,
        list: action.data.credit_notes,
        total: action.data.total_amount
      }

    default:
      return state
  }
}

export const getCreditNotes = () => {
  return dispatch => {
    dispatch({
      type: REQ_CREDIT_NOTES
    })

    return axios.post('/credit_note/list')
    .then(({data: {err, data}}) => {

      console.log(data)

      const credit_notes = data.credit_notes

      dispatch({
        type: REC_CREDIT_NOTES,
        data: {credit_notes}
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getCreditNotesBySeller = (created_by) => {
  return dispatch => {
    dispatch({
      type: REQ_CREDIT_NOTES
    })

    return axios.post('/credit_note/by_traders/list', {created_by})
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_CREDIT_NOTES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}