import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import Login from '../Login';
import { connect } from 'react-redux'
import {Redirect } from 'react-router';
import {getCurrentUser} from '../../modules/user';

class App extends Component{

	componentDidMount(){
		this.props.getCurrentUser();
	}

	render(){
		const {gettingUser, isLoggedin} = this.props;

		if(gettingUser){
			return <div> Loading ... </div>
		} else {
			return (
				<div>
					<main>
						<Route exact path="/login" render={() => (
							isLoggedin ? (
								<Redirect to="/dashboard/accounts"/>
							) : (
								<Login/>
							)
						)}/>

						<Route exact path="/" render={() => (
							isLoggedin ? (
								<Redirect to="/dashboard/accounts"/>
							): (
								<Redirect to="login"/>
							)
						)}/>

						
					</main>
				</div>
			)
		}
	}
}
		

const nick = (state) => {
	return {
		isLoggedin: state.user.isLoggedin,
		gettingUser: state.user.gettingUser
	}
}

export default connect(nick, {getCurrentUser})(App)
