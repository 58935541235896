import React, { Component } from 'react';
import styled from 'styled-components';
import CreditNotes from '../CreditNotes';
import PendingPayments from '../PendingPayments';
import UpcomingPayments from '../UpcomingPayments';
import OverduePayments from '../OverduePayments';
import HistoricalData from '../HistoricalData';
import CommissionInvoices from '../CommissionInvoices';
import Accounts from '../Accounts';
import { Route, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, getCurrentUser } from '../../modules/user';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const NavPanel = styled.div `
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 300px;
	background: #f5f5f5;
	border-right: 1px solid #ddd;
	z-index: 1;
`

const Content = styled.div `

`

const Page = styled.div `
	padding-left: 300px;
`

const NavHead = styled.div `
	height: 120px;
	border-bottom: 1px solid #e5e5e5;
`

const Avatar = styled.div `
	background: #0c6382;
	color: white;
	border-radius: 40px;
	height: 35px;
	width: 35px;
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`
const Name = styled.div `
	line-height: 35px;
	margin-left: 10px;
	width: 225px;
	font-size: 18px;
`
const Email = styled.div `
    padding-left: 60px;
    position: relative;
    top: -15px;
    opacity: .5;
`
const Entity = styled.div `
	padding-left: 60px;
    position: relative;
    top: -15px;
    opacity: .5;
`
const Info = styled.div `
	display: flex;
	padding: 15px;
`

const Logout = styled.div `
	position: fixed;
	top: 15px;
	right: 15px;
`

const NavItem = styled.div `
	line-height: 40px;
	opacity: .7;

	&:hover{
		background: white;
		opacity: 1;
		cursor: pointer;
	};
	position: relative;
	height: 40px;
`

const ListLink = styled(NavLink) `
	color: black;
	opacity: .9;
	text-decoration: none;
	width: 100%;
	position: absolute;
	top: 0;
	left:0;
	padding-left: 30px;
	right: 0;
	bottom: 0;
`

const NavBody = styled.div `
	margin-top: 20px;
`

const SwitchEntity = styled.div `
	position: fixed;
	top: 15px;
	right: 120px;
`

const EntityName = styled.div `
	line-height: 40px;
	cursor: pointer;
	padding-left: 20px;
	&:hover{
		background: #f5f5f5;
	}
	color: ${props => props.isCurrent ? 'blue' : 'auto'};
`

const Actions = styled.div `
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 60px;
	background: white
`

const Children = styled.div `
	padding-left: 20px;
`

const Child = styled.div `
	padding-left: 20px;
`

const DialogBody = styled.div `
	width: 400px;
	padding: 30px;
	padding-top: 0;
`

class Dashboard extends Component {

	componentDidMount(){
		this.props.getCurrentUser()
	}

	initials(str){
		if(str){
			var arr = str.split(' ');
			return `${arr[0][0]}${arr[1][0]}`	
		} else {
			return ''
		}
	}

	handleClose(){
		this.setState({
			open: false
		})
	}

	state = {
		open: false
	}

	showEntitySelection(){
		this.setState({
			open: true
		})
	}

	getTree(entity){
		const {current_entity} = this.props;
		return (
			<div key={entity.entityId}>
				<EntityName isCurrent={current_entity ? entity.entityId == current_entity.entityId : false} onClick={() => this.switchEntity(entity)} >
					{entity.entityName}
				</EntityName>
				
					{ entity.children && entity.children.length ? (
							<Child>
								{entity.children.map((child => this.getTree(child)))}
							</Child>
						) : ''
					}
				
			</div>
		)
	}

	switchEntity(entity){
		axios.post('/users/switch-entity', {entity: entity}).then((response) => {
	      if(response.data){
	        if(response.data.err){
	          
	        } else {
	          window.location.reload()
	        }
	      }      
	    }).catch((err) => {
	      
	    });
	}

	render() {
		const {locks, account, current_entity} = this.props;

		const entities = locks.tree;

		return (
			<Content>
				<NavPanel>
					<NavHead>
						<Info>
							<Avatar>
								{account ? this.initials(account.name) : ''}
							</Avatar>
							<Name>
								{account ? account.name: ''}
							</Name>
						</Info>
						<Email>
							{account ? account.email: ''}
						</Email>
						<Entity>
							{current_entity ? current_entity.entityName : 'All Entities'}
						</Entity>
					</NavHead>
					<Dialog onClose={() => this.handleClose()} open={this.state.open} maxWidth={"lg"}>
				        <DialogTitle>Select Entity</DialogTitle>
				        <DialogBody>
				        	<EntityName isCurrent={!current_entity} onClick={() => this.switchEntity()} >
								All
							</EntityName>
							{entities.map((entity) => this.getTree(entity))}
				        </DialogBody>
				     </Dialog>
				     {!account.is_seller ? (
				     	<NavBody>
							<NavItem>
								<ListLink to="/dashboard/accounts">
									Accounts
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/overdue-payments" activeClassName='is-active-left-link'>
									Overdue Payments
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/upcoming-payments" activeClassName='is-active-left-link'>
									Upcoming Payments
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/pending-payments" activeClassName='is-active-left-link'>
									Pending Payments
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/credit-notes" activeClassName='is-active-left-link'>
									Credit Notes
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/historical-data">
									Historical Data
								</ListLink>
							</NavItem>
						</NavBody>
				     ) : (
				     	<NavBody>
				     		<NavItem>
								<ListLink to="/dashboard/accounts">
									Accounts
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/overdue-receivables" activeClassName='is-active-left-link'>
									Overdue Receivables
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/upcoming-receivables" activeClassName='is-active-left-link'>
									Upcoming Receivables
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/pending-receivables" activeClassName='is-active-left-link'>
									Pending Receivables
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/debit-notes" activeClassName='is-active-left-link'>
									Debit Notes
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/seller-historical-data">
									Historical Data
								</ListLink>
							</NavItem>
							<NavItem>
								<ListLink to="/dashboard/commission-invoices">
									Commission Invoices
								</ListLink>
							</NavItem>
						</NavBody>
				     )}
				</NavPanel>
				<Actions>
					<SwitchEntity>
						<Button variant="outlined" onClick={() => this.showEntitySelection()}>
				        	Switch Entity
				      	</Button>
				    </SwitchEntity>
					<Logout>
						<Button variant="outlined" onClick={() => this.props.logout()}>
							Logout
						</Button>
					</Logout>
				</Actions>
			{!account.is_seller ? (
				<Page>
					<Route path="/dashboard/accounts" component={Accounts}/>
					<Route path="/dashboard/credit-notes" component={CreditNotes}/>
					<Route path="/dashboard/pending-payments" component={PendingPayments}/>
					<Route path="/dashboard/upcoming-payments" component={UpcomingPayments}/>
					<Route path="/dashboard/overdue-payments" component={OverduePayments}/>
					<Route path="/dashboard/historical-data" component={HistoricalData}/>
				</Page>
			) : (
				<Page>
					<Route path="/dashboard/accounts" component={Accounts}/>
					<Route path="/dashboard/debit-notes" component={CreditNotes}/>
					<Route path="/dashboard/pending-receivables" component={PendingPayments}/>
					<Route path="/dashboard/upcoming-receivables" component={UpcomingPayments}/>
					<Route path="/dashboard/overdue-receivables" component={OverduePayments}/>
					<Route path="/dashboard/seller-historical-data" component={HistoricalData}/>
					<Route path="/dashboard/commission-invoices" component={CommissionInvoices}/>
				</Page>
			)}
			</Content>
		)
	}
}

const mapStateToProps = state => {
	return {
		account: state.user.account,
		locks: state.user.locks,
		current_entity: state.user.current_entity,
		account: state.user.account
	}
}


export default connect(mapStateToProps, { logout, getCurrentUser })(Dashboard)

