import { combineReducers } from 'redux'
import counter from './counter'
import user from './user'
import creditNote from './credit-note'
import invoice from './invoice'
import payment from './payment'

export default combineReducers({
  counter,
  invoice,
  user,
  creditNote,
  payment
})
