import React, { Component } from 'react';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCommissionInvoices } from '../../modules/invoice';
import moment from 'moment';

const Container = styled.div `
  padding: 40px;
`

const Loading = styled.div `

`

const Content = styled.div `

`

const Total = styled.div `

`

const Pay = styled.td `
	color: #0c6382;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: .2s all;
	opacity: .7;

	&:hover{
		opacity: 1;
	};
`

const NoNotes = styled.div `
	width: 100%;
	max-width: 1200px;
	text-align: center;
	margin: 0px auto;
	margin-top: 100px;
`

const DueDate = styled.td `
	color: #f29000;
`

const InvoiceList = styled.table `
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;
	margin-top: 40px;
	font-size: 12px;

	th{
		background: black;
		height: 40px;
		color: white;
		font-weight: normal;
		border: 1px solid white;
	}

	td{
		text-align: center;
		height: 40px;
		border: 1px solid #ccc;
	}
`

const TotalLabel = styled.div `
	font-size: 16px;
	opacity: .4;
`

const TotalAmount = styled.div `
	font-size: 24px;
	margin-top: 5px;
	color: #f29000;
`

class CommissionInvoices extends Component {

	componentDidMount(){
		this.props.getCommissionInvoices();
	}

	pay(invoice){
		this.setState({
			invoice
		})
	}

	invoiceList(){
		var { invoices, total, account } = this.props;

		invoices = invoices.map((invoice) => {
			invoice.url = invoice.invoice_url;

			if(!invoice.invoice_url){
				if(invoice.attachment && invoice.attachment.uuid){
					invoice.url = `http://${window.location.hostname}:4000/resources/${invoice.attachment.uuid}`
				}
			}

			return invoice
		})

		return (
			<Content>
				<Total>
					<TotalLabel>
						Total Commission Amount
					</TotalLabel>
					<TotalAmount>
						Rs. {total}
					</TotalAmount>
				</Total>
				<InvoiceList>
					<thead>
						<tr>
							<th>
								Invoice Ref
							</th>
							<th>
								Seller
							</th>
							<th>
								Amount
							</th>
							<th>
								Balance Due
							</th>
							<th>
								PO ID
							</th>
							<th>
								Invoice Date
							</th>
							<th>
								Shipping Charge
							</th>
							<th>
								Payment Status
							</th>
							<th>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{invoices.map((invoice) => (
							<tr key={invoice._id} >
								<td>
									<a href={invoice.url} target="_blank">
										{invoice.number}
									</a>
								</td>
								<td>
									{invoice.po.seller.shop}
								</td>
								<td>
									Rs. {invoice.amount}
								</td>
								<td>
									Rs. {invoice.balance_due}
								</td>
								<td>
									PO - {invoice.po.id_po}
								</td>
								<td>
									{moment(invoice.invoice_date).format('DD/MM/YYYY ')}
								</td>
								<td>
									Rs. {invoice.shipping_charge}
								</td>
								<td>
									{invoice.payment_status}
								</td>
								<Pay onClick={() =>  this.pay(invoice)}>
									Pay
								</Pay>
							</tr>
						))} 
					</tbody>
				</InvoiceList>
			</Content>
		)
	}

	render(){
		const {invoices, req} = this.props;
		return (
			<Container>
				{req && <Loading> Loading ... </Loading>}
				{!req && !!invoices.length && this.invoiceList()}
				{!req && !invoices.length && <NoNotes> You have no Commission Invoices </NoNotes> }
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		req: state.invoice.commission_invoices.req,
		invoices: state.invoice.commission_invoices.invoices,
		total: state.invoice.commission_invoices.total_amount,
		account: state.user.account
	}
}


export default withRouter(connect(mapStateToProps, { getCommissionInvoices })(CommissionInvoices))
