import axios from 'axios';

export const REQ_LOGIN = 'REQ_LOGIN'
export const REC_LOGIN = 'REC_LOGIN'
export const ERR_LOGIN = 'ERR_LOGIN'

export const REQ_USER = 'REQ_USER'
export const REC_USER = 'REC_USER'
export const ERR_USER = 'ERR_USER'

const initialState = {
  isLoggedin : false,
  loggingIn: false,  
  account: {},
  current_entity: {},
  locks: {
    tree: []
  },
  gettingUser: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQ_USER:
      state.gettingUser = true;
      break;
    case REC_USER:
      state.gettingUser = false;
      if(action.user){
        state.isLoggedin = true;
        state.account = action.user.account;
        state.current_entity = action.user.current_entity;
        state.locks = action.user.locks;
      } else {
        state.isLoggedin = false;
      }
      break;
    case REQ_LOGIN:
      state.loggingIn = true;
      break;
    case REC_LOGIN:
      state.isLoggedin = true;
      state.loggingIn = false;
      state.account = action.user.account;
      state.current_entity = action.user.current_entity;
      state.locks = action.user.locks;
      break;
    default:
      return state
  }

  return state
}

export function logout(){
  return (dispatch) => {
    axios.post('/users/logout').then((response) => {
      if(response.data){
        if(response.data.err){
          
        } else {
          window.location="/login"
        }
      }      
    }).catch((err) => {
      
    });
  }
}

export function getCurrentUser(){
  return (dispatch) => {
    dispatch({
      type: REQ_USER
    });
    axios.post('/users/current').then((response) => {

      if(response.data){
      
        if(response.data.err){
          dispatch({
            type: REC_USER,
            user: null
          });    
        } else {
      
          dispatch({
            type: REC_USER,
            user: response.data.data.user
          });
        }
      }      
    }).catch((err) => {
      
    });
  }
}

export const login = ({email, password}) => {
  return dispatch => {
    dispatch({
      type: REQ_LOGIN
    });
    axios.post('/users/login', {email, password})
    .then(({data}) => {
      if(!data.err){
        dispatch({
          type: REC_LOGIN,
          user: data.data ? data.data.user : undefined
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ERR_LOGIN
      })
    })
  }
}