import React, { Component } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const BackDrop = styled.div `
	position: fixed;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #f5f5f5;
`

const Container = styled.div `
	width: 450px;
	height: 505px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: fixed;
	border: 1px solid #ddd;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
`

class PaymentSuccess extends Component {

	constructor(props){
		super(props)
	}

	componentDidMount(){
		if(window.parent){
			window.parent.postMessage({payment:{ status: "SUCCESS", reference: this.props.match.params.id_payment}}, '*')
		}
	}

	render(){
		return (
			<BackDrop>
				<Container>
					Payment Successful
				</Container>
			</BackDrop>
		)
	}
}

export default withRouter(PaymentSuccess)
