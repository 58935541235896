import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import Dashboard from './containers/Dashboard';
import Payment from './containers/Payment';
import PaymentSuccess from './containers/PaymentSuccess';
import PaymentFail from './containers/PaymentFail';
import { Route } from 'react-router-dom'

import 'sanitize.css/sanitize.css'
import './index.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
        <Route path="/dashboard" component={Dashboard}/>
        <Route path="/payment/success" component={PaymentSuccess}/>
        <Route path="/payment/failure" component={PaymentFail}/>
        <Route path="/payment/cancel" component={PaymentFail}/>
        <Route path="/payment/attempt/:id_payment" component={Payment}/>
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
