import React, { Component } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux'
import { getCreditNotesBySeller } from '../modules/credit-note'
import { logOutstandingPayment } from '../modules/payment'
import CONSTANTS  from '../constants'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField';

const Container = styled.div `
	height: 400px;
	width: 600px;
	background: white;
	box-shadow: 0px 0px 20px 0px #bbb;
	position: fixed;
	top: 0;
	z-index: 1;
	transform: ${props => props.invoice ? 'translate(-50%, 500px)' : 'translate(-50%, 0)'};
	left: 50%;
	transition: .2s all;
	top: -500px;
`

const Content = styled.div `

`

const Label = styled.th `
	width: 300px;
`

const Remaining = styled.th `
	width: 150px;
`

const Use = styled.th `
	width: 150px;
`

const InvoiceTable = styled.table `
	width: 100%;
	margin-top: 10px;

	td{
		text-align: center;
		line-height: 40px;
	}

	th{
		opacity: .4;
		font-weight: normal;
	}
`

const Input = styled.td `
	width: 120px;
	padding-right: 10px;

	input{
		border:0;
		border-bottom: 1px solid #ddd;
		width: 80px;

		&:focus{
			outline: none;
		}
	}
`

const RemAmount = styled.td `
	text-align: left;
`

const PayableAmount = styled.div `
	padding-left: 20px;
	line-height: 40px;
	font-size: 20px;
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #ddd;
`

const PayableLabel = styled.div `
	float: left;
	opacity: .5;
`

const PayableValue = styled.div `
	float: left;
	margin-left: 20px;
`

const PLabel = styled.td `
	text-align: left !important;
	padding-left: 20px;
`

const Pay = styled.div `
	position: absolute;
	bottom: 15px;
	right: 15px;
`

const Value = styled.div `
	float: left;
	margin-right: 20px;
	font-size: 20px;
`

const CTA = styled.div `
	padding-left: 30px;
	padding-right: 30px;
	line-height: 35px;
	background: #0c6382;
	color: white;
	cursor: pointer;
	border-radius: 3px;
	float: left;
`

const Error = styled.div `
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: red;
	font-size: 12px;
`

const Cancel = styled.div `
	position: absolute;
	top: 15px;
	right: 10px;
	line-height: 40px;
	font-size: 15px;
	cursor: pointer;
	opacity: .6;

	&:hover{
		opacity: 1;
	};
`

const Calculation = styled.div `
	width: 100%;
	padding-left: 20px;
	display: inline-block;
`

const IntendedAmount = styled.div `
	float: left;
	margin-right: 20px;
`

const Discount = styled.div `
	float: left;
`

class PaymentExecution extends Component {

	componentDidUpdate(prevProps){
		var { invoice, credit_notes } = this.props;

		if(invoice && !prevProps.invoice){
			this.props.getCreditNotesBySeller(invoice.created_by)

			var po = invoice.po;
			var total_po_amount = po.amount_paid + po.amount_left;

			this.setState({
				pay_amount: invoice.balance_due,
				amount_excl_discount: invoice.balance_due,
				advance_remaining: invoice.po.advance_paid - invoice.po.advance_settled
			})
		}

		if(credit_notes && !prevProps.credit_notes.length){
			if(credit_notes.length){
				var local_credit_notes = _.map(credit_notes, (credit_note) => {
					credit_note.use = 0
					return credit_note
				})
				this.setState({local_credit_notes})
			}
		}
	}

	handleAmountChange(e){
		const value = e.target.value;

		const amt = parseFloat(value)

		this.setState({
			amount_excl_discount: amt ? amt : 0
		})

		this.setPayableAmount(this.state.local_credit_notes, this.state.advance_used, parseFloat(value))
	}

	setPayableAmount(credit_notes, advance_used, amount_excl_discount){
		// check advance payment for the remaining amount

		var total_usage = 0;

		if(advance_used > this.state.advance_remaining){
			this.setState({
				error: 'Advance used cannot be greated than Advance paid'
			})
			return;
		} else {
			total_usage = advance_used
		}

		var allow = true;

		_.each(credit_notes, (credit_note) => {
			if(credit_note.use > credit_note.remaining_amount){
				this.setState({
					error: 'Cannot use more than remaining amount for CN-' + credit_note.id
				})
				allow = false
			} else {
				total_usage = total_usage + credit_note.use
			}
		})

		if(!allow){
			return
		}

		if(total_usage > this.props.invoice.balance_due){
			this.setState({
				error: 'Final price (after discounts) cannot be greater than balance due'
			})
			return
		}

		if(amount_excl_discount < total_usage){
			this.setState({
				error: 'Settle Amount cannot be less than total reduction'
			})
			return	
		}
		
		this.setState({
			discounts: total_usage,
			pay_amount: amount_excl_discount - total_usage,
			error: ''
		})	
		
		// check final payable value 
	}

	initiatePayment(){

	}

	state = {
		local_credit_notes: [],
		advance_used: 0,
		error: '',
		pay_amount: 0,
		amount_excl_discount: 0,
		discounts: 0
	}

	attemptPay(){
		const {advance_used, local_credit_notes} = this.state;

		const credit_notes_used = _.map(local_credit_notes, (credit_note) => {
			return {
				credit_note_id: credit_note._id,
				amount_used: credit_note.use
			}
		})

		const params = {
			paid_to: this.props.invoice.po.seller.id,
			credit_notes_used,
			advance_used,
			inv_ref: this.props.invoice.number,
			po_ref: this.props.invoice.po.id_po,
			amount: this.state.pay_amount
		}

		this.props.logOutstandingPayment(params)
	}

	handleAdvance(e){
		const advance_used = e.target.value ? parseFloat(e.target.value) : ''
		this.setState({
			advance_used
		})

		this.setPayableAmount(this.state.local_credit_notes, advance_used, this.state.amount_excl_discount)
	}

	handleChange(e, credit_note){
		const value = e.target.value ? parseFloat(e.target.value) : 0
		credit_note.use = value;
		const credit_notes = this.state.local_credit_notes;
		this.setState({...credit_notes})

		this.setPayableAmount(credit_notes, this.state.advance_used, this.state.amount_excl_discount)
	}

	render(){
		const { invoice } = this.props;
		const {local_credit_notes, advance_used, error, pay_amount, advance_remaining} = this.state;
		
		return (
			<Container invoice={invoice}>
				{!!invoice && (
					<Content>
						<PayableAmount>
							<PayableLabel>
								Balance Due
							</PayableLabel>
							<PayableValue>
								Rs. {invoice.balance_due}
							</PayableValue>
							<Cancel onClick={() => this.props.cancel()}>
								Cancel
							</Cancel>
						</PayableAmount>

						<InvoiceTable>
							<thead>
								<tr>
									<Label>
									</Label>	
									<Remaining>
										Remaining
									</Remaining>
									<Use>
										Use
									</Use>
								</tr>
							</thead>
							<tbody>
								<tr>
									<PLabel>
										Excess Payment	
									</PLabel>
									<RemAmount>
										Rs. {advance_remaining}
									</RemAmount>
									<Input>
										Rs. <input type="number" value={advance_used} onChange={(e) => this.handleAdvance(e)} min="0" />
									</Input>
								</tr>
								{!!local_credit_notes && local_credit_notes.map((credit_note) => (
									<tr key={credit_note._id} >
										<PLabel>
											CN - {credit_note.id}
										</PLabel>
										<RemAmount>
											Rs. {credit_note.remaining_amount}
										</RemAmount>
										<Input>
											Rs. <input type="number" value={credit_note.use} onChange={(e) => this.handleChange(e, credit_note)} min="0" />
										</Input>
									</tr>
								))}
							</tbody>
						</InvoiceTable>
						<Calculation>
							<IntendedAmount>
								<TextField
									label="Settle Amount"
									value={this.state.amount_excl_discount}
									onChange={(e) => this.handleAmountChange(e)}
									margin="normal"
								/>
							</IntendedAmount>
							<Discount>
								<TextField
									label="Reduction"
									disabled
									type="number"
									value={this.state.discounts}
									margin="normal"
								/>
							</Discount>
						</Calculation>
						<Error>
							{error}
						</Error>
						<Pay>
							<Value> 
								Rs. {pay_amount}
							</Value>
							<CTA onClick={() => this.attemptPay()} >
								Pay
							</CTA>
						</Pay>
					</Content>
				)}
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoggedin : state.user.isLoggedin,
		credit_notes: state.creditNote.list
	}
}


export default connect(mapStateToProps, { getCreditNotesBySeller, logOutstandingPayment })(PaymentExecution)
