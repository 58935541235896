import React, { Component } from 'react';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUpcomingInvoices } from '../../modules/invoice';
import {paymentFlush} from '../../modules/payment';
import moment from 'moment';
import PaymentExecution from '../../components/PaymentExecution';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Container = styled.div `
  padding: 40px;
`

const Loading = styled.div `

`

const NoNotes = styled.div `
	width: 100%;
	max-width: 1200px;
	text-align: center;
	margin: 0px auto;
	margin-top: 100px;
`

const Content = styled.div `

`

const Pay = styled.td `
	color: #0c6382;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: .2s all;
	opacity: .7;

	&:hover{
		opacity: 1;
	};
`

const Total = styled.div `

`
const DueDate = styled.td `
	color: #f29000;
`

const InvoiceList = styled.table `
	width: 100%;
	
	margin: 0px auto;
	margin-top: 20px;
	font-size: 12px;

	th{
		background: black;
		height: 40px;
		color: white;
		font-weight: normal;
		border: 1px solid white;
	}

	td{
		text-align: center;
		height: 40px;
		border: 1px solid #ccc;
	}
`

const TotalLabel = styled.div `
	font-size: 20px;
	opacity: .4;
`

class UpcomingPayments extends Component {

	componentDidMount(){
		this.props.getUpcomingInvoices();
	}

	componentDidUpdate(prevProps) {
		if(this.props.paymentComplete && !prevProps.paymentComplete){
			this.setState({
				invoice: undefined
			})
			this.props.paymentFlush();
		}
	}	

	pay(invoice){
		this.setState({
			invoice
		})
	}

	cancel(){
		this.setState({
			invoice: undefined
		})
	}

	state = {}

	invoiceList(){
		var { invoices, account } = this.props;

		invoices = invoices.map((invoice) => {
			invoice.url = invoice.invoice_url;

			if(!invoice.invoice_url){
				if(invoice.attachment && invoice.attachment.uuid){
					invoice.url = `http://${window.location.hostname}:4000/resources/${invoice.attachment.uuid}`
				}
			}

			return invoice
		})

		return (
			<Content>
				<PaymentExecution invoice={this.state.invoice} cancel={() => this.cancel()} />
				<Total>
					<TotalLabel>
						Invoices to be paid in next 30 Days
					</TotalLabel>
				</Total>
				<InvoiceList>
					<thead>
						<tr>
							<th>
								Invoice Ref
							</th>
							<th>
								{account.is_seller ? 'Buyer' : 'Seller'}
							</th>
							<th>
								Amount
							</th>
							<th>
								Balance Due
							</th>
							{!account.is_seller && (
							<th>
								Buyer PO Id
							</th>
							)}
							<th>
								PO ID
							</th>
							<th>
								Invoice Date
							</th>
							<th>
								Due Date
							</th>
							<th>
								Payment Status
							</th>
							{!account.is_seller && (
							<th>
								Action
							</th>
							)}
						</tr>
					</thead>
					<tbody>
						{invoices.map((invoice) => (
							<tr key={invoice._id} >
								<td>
									<a href={invoice.url} target="_blank">
										{invoice.number}
									</a>
								</td>
								<td>
									{!account.is_seller ? invoice.po.seller.shop : invoice.po.customer.company}
								</td>
								<td>
									Rs. {invoice.amount}
								</td>
								<td>
									Rs. {invoice.balance_due}
								</td>
								{!account.is_seller && (
								<td>
									{invoice.buyer_po_id ? invoice.buyer_po_id : '-'}
								</td>
								)}
								<td>
									PO - {invoice.po.id_po}
								</td>
								<td>
									{moment(invoice.invoice_date).format('DD/MM/YYYY ')}
								</td>
								<DueDate>
									{invoice.payment_due_date ?  moment(invoice.payment_due_date).format('DD/MM/YYYY ') : '-'}
								</DueDate>
								<td>
									{invoice.payment_status}
								</td>
								{!account.is_seller && (
									<Pay onClick={() =>  this.pay(invoice)}>
										Pay
									</Pay>
								)}
							</tr>
						))} 
					</tbody>
				</InvoiceList>
			</Content>
		)
	}

	render(){
		const {invoices, req} = this.props;
		return (
			<Container>
				{req && <Loading> Loading ... </Loading>}
				{!req && !!invoices.length && this.invoiceList()}
				{!req && !invoices.length && <NoNotes> You have no Upcoming Payments to be made </NoNotes>}
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		req: state.invoice.upcoming_invoices.req,
		invoices: state.invoice.upcoming_invoices.invoices,
		total: state.invoice.upcoming_invoices.total_amount,
		account: state.user.account,
		paymentComplete: state.payment.paymentComplete
	}
}


export default withRouter(connect(mapStateToProps, { getUpcomingInvoices, paymentFlush })(UpcomingPayments))
