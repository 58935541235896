const axios = require('axios');

export const REQ_OVERDUE_INVOICES = 'REQ_OVERDUE_INVOICES'
export const REC_OVERDUE_INVOICES = 'REC_OVERDUE_INVOICES'

export const REQ_UPCOMING_INVOICES = 'REQ_UPCOMING_INVOICES'
export const REC_UPCOMING_INVOICES = 'REC_UPCOMING_INVOICES'

export const REQ_PENDING_INVOICES = 'REQ_PENDING_INVOICES'
export const REC_PENDING_INVOICES = 'REC_PENDING_INVOICES'

export const REQ_RECENT_PAID_INVOICES = 'REQ_RECENT_PAID_INVOICES'
export const REC_RECENT_PAID_INVOICES = 'REC_RECENT_PAID_INVOICES'

export const REQ_PAID_INVOICES = 'REQ_PAID_INVOICES'
export const REC_PAID_INVOICES = 'REC_PAID_INVOICES'

export const REQ_COMMISSION_INVOICES = 'REQ_COMMISSION_INVOICES'
export const REC_COMMISSION_INVOICES = 'REC_COMMISSION_INVOICES'

export const REC_UPCOMING_SUMMARY = 'REC_UPCOMING_SUMMARY'

const initialState = {

  upcoming_summary:{
    amount_eom: 0,
    amount_eoq: 0,
    amount_eoy: 0,
    req: false
  },

  overdue_invoices: {
    total: 0,
    req: false,
    invoices: []
  },

  upcoming_invoices: {
    total: 0,
    req: false,
    invoices: []
  },

  pending_invoices: {
    total: 0,
    req: false,
    invoices: []
  },

  recently_paid_invoices: {
    total: 0,
    req: false,
    invoices: []
  },

  paid_invoices: {
    total: 0,
    req: false,
    invoices: []
  },

  commission_invoices: {
    total: 0,
    req: false,
    invoices: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REQ_OVERDUE_INVOICES:
      const overdue_invoices = state.overdue_invoices;
      overdue_invoices.req = true;
      return {
        ...state,
        overdue_invoices: { ...overdue_invoices }
      }
    case REQ_UPCOMING_INVOICES:
      const upcoming_invoices = state.upcoming_invoices;
      upcoming_invoices.req = true;

      return {
        ...state,
        upcoming_invoices: { ...upcoming_invoices }
      }
    case REQ_PAID_INVOICES:
      const paid_invoices = state.paid_invoices;
      paid_invoices.req = true;

      return {
        ...state,
        paid_invoices: { ...paid_invoices }
      }

    case REQ_PENDING_INVOICES:
      const pending_invoices = state.pending_invoices;
      pending_invoices.req = true;

      return {
        ...state,
        pending_invoices: { ...pending_invoices }
      }

    case REQ_RECENT_PAID_INVOICES:
      const recently_paid_invoices = state.recently_paid_invoices;
      recently_paid_invoices.req = true;

      return {
        ...state,
        recently_paid_invoices: { ...recently_paid_invoices }
      }

    case REQ_COMMISSION_INVOICES:
      const commission_invoices = state.commission_invoices;
      commission_invoices.req = true;

      return {
        ...state,
        commission_invoices: { ...commission_invoices }
      }

    case REC_UPCOMING_SUMMARY:
      var upcoming_summary = action.data;
      
      return {
        ...state,
        upcoming_summary: { ...upcoming_summary }
      }

    case REC_PENDING_INVOICES:
      var rec_pending_invoices = action.data;
      rec_pending_invoices.req = false;

      return {
        ...state,
        pending_invoices: { ...rec_pending_invoices }

      }

    case REC_COMMISSION_INVOICES:
      var rec_commission_invoices = action.data;
      rec_commission_invoices.req = false;

      return {
        ...state,
        commission_invoices: { ...rec_commission_invoices }

      }

    case REC_PAID_INVOICES:
      var rec_paid_invoices = action.data;
      rec_paid_invoices.req = false;

      return {
        ...state,
        paid_invoices: { ...rec_paid_invoices }

      }

    case REC_UPCOMING_INVOICES:
      var rec_upcoming_invoices = action.data;
      rec_upcoming_invoices.req = false;

      return {
        ...state,
        upcoming_invoices: { ...rec_upcoming_invoices }

      }

    case REC_OVERDUE_INVOICES:
      var rec_overdue_invoices = action.data;
      rec_overdue_invoices.req = false;

      return {
        ...state,
        overdue_invoices: { ...rec_overdue_invoices }

      }

    default:
      return state
  }
}

export const getUpcomingSummary = () => {
  return dispatch => {
    return axios.post('/invoice/upcoming_summary')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_UPCOMING_SUMMARY,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getOverdueInvoices = () => {
  return dispatch => {
    dispatch({
      type: REQ_OVERDUE_INVOICES
    })

    return axios.post('/invoice/overdue_invoice/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_OVERDUE_INVOICES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getCommissionInvoices = () => {
  return dispatch => {
    dispatch({
      type: REQ_COMMISSION_INVOICES
    })

    return axios.post('/invoice/commission_invoice/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_COMMISSION_INVOICES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getPaidInvoices = () => {
  return dispatch => {
    dispatch({
      type: REQ_PAID_INVOICES
    })

    return axios.post('/invoice/paid_invoice/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_PAID_INVOICES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getPendingInvoices = () => {
  return dispatch => {
    dispatch({
      type: REQ_PENDING_INVOICES
    })

    return axios.post('/invoice/pending_invoice/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_PENDING_INVOICES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

// export const attemptPayment = (invoice_id, amount) => {
//   if(!this.state.error){
//     window.open( ACCOUNTING_WEBAPP + '/payment/' + response.data.id_payment ,'newwindow', config='height=670,width=720,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
//   }
// }

export const getUpcomingInvoices = () => {
  return dispatch => {
    dispatch({
      type: REQ_UPCOMING_INVOICES
    })

    return axios.post('/invoice/upcoming_invoice/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_UPCOMING_INVOICES,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}
