import React, { Component } from 'react';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {getCreditNotes} from '../../modules/credit-note';
import OrangeButton from '../../components/OrangeButton';
import moment from 'moment';


const Container = styled.div `
  padding: 40px;
`

const Loading = styled.div `

`

const NoNotes = styled.div `
	width: 100%;
	text-align: center;
`

const CNList = styled.div `
	margin-top: 20px;
	display: inline-block;
`

const Content = styled.div `

`

const Total = styled.div `

`

const TotalLabel = styled.div `
	font-size: 16px;
	opacity: .4;
`

const TotalAmount = styled.div `
	font-size: 24px;
	margin-top: 5px;
`

const CreditNoteBox = styled.div `
	height: 260px;
	width: 300px;
	box-shadow: 1px 1px 15px 0px #ddd;
	float: left;
	margin-left: ${props => props.index%3 ? '30px' : '0px'}
	clear: ${props => props.index%3 ? 'none' : 'left'}
	margin-top: 30px;
	position: relative;
	overflow: hidden;
	transition: .2s all;

	&:hover{
		transform: scale(1.05);
		box-shadow: 1px 1px 30px 0px #bbb;
	};
`

const SellerName = styled.div `
	padding-top: 15px;
	padding-left: 15px;
	font-size: 18px;
	font-weight: bold;
	opacity: .4;
`

const PORef = styled.div `
	background: #47A5FC;
	color: white;
	width: 200px;
	line-height: 25px;
	position: absolute;
	top: 30px;
	right: -55px;
	transform: rotate(40deg);
	text-align: center;
	font-weight: bold;
	font-size: 14px;
`

const Amount = styled.div `
	margin-top: 30px;	
	margin-left: 15px;
	
`

const AmountLabel = styled.td `
	opacity: .7;
	font-size: 12px;
`

const AmountValue = styled.td `
	font-weight: bold;
	padding-left: 10px;
	opacity: .7;
`

const POAmount = styled.div `
	opacity: .6;
	font-size: 12px;
	margin-left: 15px;
	margin-top: 10px;
`

const POAmountLabel = styled.td `
	
`

const POAmountValue = styled.td `
	padding-left: 10px;
`

const More = styled.table `
	width: 100%;
	height: 35px;
	line-height: 35px;
	background: #f5f5f5;
	margin-top: 40px;
	font-size: 12px;
	opacity: .7;
	cursor: pointer;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;

	&:hover{
		opacity: 1;
	};
`

const MoreNum = styled.td `
	padding-left: 15px;
`

const MoreAmount = styled.td `
	text-align: right;
	padding-right: 15px;
`

const Footer = styled.table `
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
`

const Expiry = styled.td `
	font-size: 10px;
	width: 40%;
	opacity: .5;
	padding-left: 15px;
	padding-bottom: 10px;
`

const ExpiryLabel = styled.div `

`

const ExpiryValue = styled.div `

`

const Actions = styled.td `
	text-align: right;
	padding-right: 10px;
	padding-bottom: 10px;
`

const ActionCont = styled.table `
	width: 100%;
`

const RedeemCTA = styled.td `

`

const ApplyCTA = styled.td `

`

// const Redeem = styled(OrangeButton)`
// 	background: #f5f5f5;
// 	font-size: 12px;
// 	padding-left: 15px;
// 	padding-right: 15px;
// 	line-height: 25px;
// 	font-weight: normal;
// 	border-radius: 0;
// 	color: black;
// `

const Apply = styled(OrangeButton)`
	font-size: 12px;
	padding-left: 15px;
	padding-right: 15px;
	line-height: 25px;
	font-weight: normal;
	border-radius: 0;
`

const Depth = styled.div `
	position: fixed;
	left: 50%;
	transform: ${props => props.visible ? 'translate(-50%, 0)' :  'translate(-50%, -150%)' } ;
	
	width: 800px;
	background: white;
	top: 0;
	box-shadow: 0px 0px 20px 0px #bbb;
	transition: .2s all;
`

const TotalCredit = styled.div `
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 20px;
	opacity: .7;
`

const CreditNotes = styled.table `
	width: 100%;
	margin-top: 30px;
	margin-bottom: 10px;
`

const Close = styled.div `
	margin-right: 30px;
    margin-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 13px;
    color: black;
    background: #e5e5e5;
    line-height: 30px;
    float: right;
    cursor: pointer;
`


class CreditNote extends Component {

	state = {
		aggr: undefined
	}

	componentDidMount(){
		this.props.getCreditNotes();
	}

	showDepth(aggr){
		this.setState({
			aggr
		})
	}

	hideDepth(){
		this.setState({
			aggr: undefined
		})
	}


	creditNotesBySeller(){

		const {aggr} = this.state;

		return (
			<Depth visible={aggr}>
				<SellerName>
					Anupam Pandey
				</SellerName>
				<TotalCredit>
					Rs. {aggr ? aggr.amount : ''}
				</TotalCredit>
				<CreditNotes className="table-monty">
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Amount
							</th>
							<th>
								Reference
							</th>
							<th>
								Issue Date
							</th>
							<th>
								Valid Till
							</th>
							<th>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{aggr ? aggr.credit_notes.map((credit_note) => (
							<tr key={credit_note.id}>
								<td>
									CN - {credit_note.id}
								</td>
								<td>
									Rs. {credit_note.amount}
								</td>
								<td>
									{credit_note.reference}
								</td>
								<td>
									{moment(credit_note.issue_date).format('DD/MM/YYYY')}
								</td>
								<td>
									{moment(credit_note.valid_till).format('DD/MM/YYYY')}
								</td>
								<td>
									<Apply>
										Apply 
									</Apply>
								</td>
							</tr>
						)) : null}
					</tbody>
				</CreditNotes>
				<Close onClick={() => this.hideDepth()}>
					Close
				</Close>
			</Depth>
		)		
	}

	creditNoteList(){
		const {list, total} = this.props;

		return (
			<Content>
				<Total>
					<TotalLabel>
						Total Credit Available
					</TotalLabel>
					<TotalAmount>
						Rs. {total}
					</TotalAmount>
				</Total>
				<CNList>
					{list.map((credit_note, index) => (
						<CreditNoteBox key={credit_note.id} index={index}> 
							<SellerName>
								Kumar & Sons
							</SellerName>
							<PORef>
								PO - {credit_note.po.id_po}
							</PORef>
							<Amount>
								<table>
									<tbody>
										<tr>
											<AmountLabel>
												Credit Amount
											</AmountLabel>
											<AmountValue>
												Rs. {credit_note.amount}
											</AmountValue>
										</tr>
									</tbody>
								</table>
							</Amount>
							<POAmount>
								<table>
									<tbody>
										<tr>
											<POAmountLabel>
												PO Amount
											</POAmountLabel>
											<POAmountValue>
												Rs. {credit_note.po.amount_paid + credit_note.po.amount_left}
											</POAmountValue>
										</tr>
									</tbody>
								</table>
							</POAmount>
							<More onClick={() => this.showDepth(credit_note.aggr)}>								
								<tbody>
									<tr>
										<MoreNum>
											{credit_note.aggr.credit_notes.length} More
										</MoreNum>
										<MoreAmount>
											Rs. {credit_note.aggr.amount}
										</MoreAmount>
									</tr>
								</tbody>
							</More>
							<Footer>
								<tbody>
									<tr>
										<Expiry>
											<ExpiryLabel>
												Valid Till
											</ExpiryLabel>
											<ExpiryValue>
												{moment(credit_note.valid_till).format('DD/MM/YYYY')}
											</ExpiryValue>
										</Expiry>
										<Actions>
											<ActionCont>
												<tbody>
													<tr>
														<RedeemCTA>
															{/*<Redeem>
																Redeem
															</Redeem>*/}
														</RedeemCTA>
														<ApplyCTA>
															<Apply>
																Apply
															</Apply>
														</ApplyCTA>
													</tr>
												</tbody>
											</ActionCont>
										</Actions>
									</tr>
								</tbody>
							</Footer>
						</CreditNoteBox>
					))}
				</CNList>
			</Content>
		)
	}

	render(){
		const {list, reqCreditNotes} = this.props;
		console.log(list, reqCreditNotes)
		return (
			<Container>
				{reqCreditNotes && <Loading> Loading ... </Loading>}
				{!reqCreditNotes && list.length && this.creditNoteList()}
				{!reqCreditNotes && !list.length && <NoNotes> You have not received any Credit Notes </NoNotes> }
				{this.creditNotesBySeller()}
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		reqCreditNotes: state.creditNote.reqCreditNotes,
		list: state.creditNote.list,
		total: state.creditNote.total
	}
}


export default withRouter(connect(mapStateToProps, {getCreditNotes})(CreditNote))
