const axios = require('axios');

export const REQ_PAYMENT_DETAIL = 'REQ_PAYMENT_DETAIL'
export const REC_PAYMENT_DETAIL = 'REC_PAYMENT_DETAIL'

export const REQ_EXECUTE = 'REQ_EXECUTE'
export const REC_EXECUTE = 'REC_EXECUTE'

export const REQ_PAID_LIST = 'REQ_PAID_LIST'
export const REC_PAID_LIST = 'REC_PAID_LIST'

export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE'
export const PAYMENT_FLUSH = 'PAYMENT_FLUSH'

const initialState = {
  detail: {},
  paidList: [],
  reqDetail: false,
  reqExecute: false,
  paymentSucess: false,
  paymentFailure: false,
  reqPaidList: false,
  paymentComplete: false
}

export default (state = initialState, action) => {
  switch (action.type) {


    case PAYMENT_FLUSH:
      return {
        ...state,
        paymentComplete: false
      }

    case REQ_PAYMENT_DETAIL:
      return {
        ...state,
        reqDetail: true
      }
    case REC_PAYMENT_DETAIL:
      return {
        ...state,
        reqDetail: false,
        detail: action.data.payment
      }

    case REQ_EXECUTE:
      return {
        ...state,
        reqExecute: true
      }

    case PAYMENT_COMPLETE:
      return {
        ...state,
        paymentComplete: true
      }

    case REC_EXECUTE:
      return {
        ...state,
        reqExecute: false,
        paymentSuccess: true,
        paymentType: action.paymentType
      }
    case REQ_PAID_LIST:
      return {
        ...state,
        reqPaidList: true
      }
    case REC_PAID_LIST:
      return {
        ...state,
        reqPaidList: false,
        paidList: action.data.payments
      }
    default:
      return state
  }
}

export const paymentFlush = () => {
  return dispatch => {
    dispatch({
      type: PAYMENT_FLUSH
    })  
  }
}

export const getPaidList = () => {
  return dispatch => {
    dispatch({
      type: REQ_PAID_LIST
    })

    return axios.post('/payment/paid/list')
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_PAID_LIST,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const executeGateway = (id_payment, gateway) => {
  return dispatch => {
    dispatch({
      type: REQ_EXECUTE
    })

    return axios.post('/payment/execute/gateway', {id_payment, gateway})
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_EXECUTE
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const executeCheque = (id_payment, reference) => {
  return dispatch => {
    dispatch({
      type: REQ_EXECUTE
    })

    return axios.post('/payment/execute/cheque', {id_payment, reference})
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_EXECUTE,
        paymentType: 'Cheque'
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const executeBankwire = (id_payment, reference) => {
  return dispatch => {
    dispatch({
      type: REQ_EXECUTE
    })

    return axios.post('/payment/execute/bankwire', {id_payment, reference})
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_EXECUTE,
        paymentType: 'Bank wire'
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const logOutstandingPayment = (params) => {
  return dispatch => {
    return axios.post('/payment/log/outstanding', params)
    .then(({data: {err, payment}}) => {
      window.addEventListener("message", function(e) {
        var data = e.data;
        if(data && data.payment && data.payment.status === "SUCCESS"){
          dispatch({
            type: PAYMENT_COMPLETE
          })
        }
      });

      window.open('/payment/' + payment._id ,'newwindow', 'height=670,width=720,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
    })
    .catch((error) => {
      console.log(error)
    })
  }
}

export const getPaymentDetail = (id_payment) => {
  return dispatch => {
    dispatch({
      type: REQ_PAYMENT_DETAIL
    })

    return axios.post('/payment/detail', {id_payment})
    .then(({data: {err, data}}) => {
      dispatch({
        type: REC_PAYMENT_DETAIL,
        data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
}