module.exports = {
	ADVANCE_PAYMENT:{
		ZERO: {
			label: '0%',
			key: 'ZERO',
			value: 0,
			select_index: 0
		},
		TEN: {
			label: '10%',
			key: 'TEN',
			value: 10,
			select_index: 1
		},
		TWENTY:{
			label: '20%',
			key: 'TWENTY',
			value: 20,
			select_index: 2
		},
		THIRTY:{
			label: '30%',
			key: 'THIRTY',
			value: 30,
			select_index: 3
		},
		FOURTY:{
			label: '40%',
			key: 'FOURTY',
			value: 40,
			select_index: 4
		},
		FIFTY:{
			label: '50%',
			key: 'FIFTY',
			value: 40,
			select_index: 5
		},
		SIXTY:{
			label: '60%',
			key: 'SIXTY',
			value: 60,
			select_index: 6
		},
		SEVENTY:{
			label: '70%',
			key: 'SEVENTY',
			value: 70,
			select_index: 7
		},
		EIGHTY:{
			label: '80%',
			key: 'EIGHTY',
			value: 80,
			select_index: 8
		},
		NINTY:{
			label: '90%',
			key: 'NINTY',
			value: 90,
			select_index: 9
		},
		HUNDRED:{
			label: '100%',
			key: 'HUNDRED',
			value: 100,
			select_index: 10
		}
	}
}