import React, { Component } from 'react';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUpcomingSummary } from '../../modules/invoice';
import { getPaidList } from '../../modules/payment';
import moment from 'moment';

const SummaryPanel = styled.div `
	width: 100%;
	display: inline-block;
`

const SummaryBlock = styled.div `
	width: 33.333%;
	float: left;
	text-align: left;
`

const SummaryLabel = styled.div `
	font-size: 18px;
	color: #6651ff;
`	
const SummaryValue = styled.div `
	margin-top: 10px;
	font-size: 22px;
`

const Container = styled.div `
	padding-left: 40px;
	padding-top: 120px;
`

const HeadLabel = styled.div `
	font-size: 25px;
	opacity: .4;
	margin-bottom: 20px;
`

const Recent = styled.div`
	margin-top: 40px;
	font-size: 25px;
	opacity: .4;
	margin-bottom: 20px;
`

const Pahada = styled.table `
	width: 90%;
	font-size: 12px;

	td{
		border: 1px solid;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	th{
		border: 1px solid white;
		background: black;
		color: white;
		font-weight: normal;
		line-height: 30px;
	}
`

class Account extends Component {

	componentDidMount(){
		this.props.getUpcomingSummary();
		this.props.getPaidList();
	}

	render(){
		const { upcoming_summary, payments, account } = this.props;
		console.log(payments)
		return (
			<Container>
				{!account.is_seller ? ( <HeadLabel> Amount To Be Paid </HeadLabel> ) : ( <HeadLabel> Amount To Be Received </HeadLabel> ) }

				<SummaryPanel>
					<SummaryBlock>
						<SummaryLabel>
							This Month
						</SummaryLabel>
						<SummaryValue>
							Rs. {upcoming_summary.amount_eom.toLocaleString()}
						</SummaryValue>
					</SummaryBlock>
					<SummaryBlock>
						<SummaryLabel>
							This Quarter
						</SummaryLabel>
						<SummaryValue>
							Rs. {upcoming_summary.amount_eoq.toLocaleString()}
						</SummaryValue>
					</SummaryBlock>
					<SummaryBlock>
						<SummaryLabel>
							This Financial Year
						</SummaryLabel>
						<SummaryValue>
							Rs. {upcoming_summary.amount_eoy.toLocaleString()}
						</SummaryValue>
					</SummaryBlock>
				</SummaryPanel>

				<Recent> Recent Payments </Recent>
				<Pahada>
					<thead>
						<tr>
							<th> Payment Id </th>
							<th> Amount </th>
							{account.is_seller ? ( <th> Buyer </th> ) : ( <th> Seller </th> ) }
							<th> Time </th>
							<th> Method </th>
							<th> Type </th>
						</tr>
					</thead>
					<tbody>
						{payments.map((payment) => (
							<tr key={payment._id}>
								<td>
									{payment.id}
								</td>
								<td>
									Rs. {payment.amount.toLocaleString()}
								</td>
								<td>
									-
								</td>
								<td>
									{moment(payment.paid_at).format('DD/MM/YYYY hh:mm')}
								</td>
								<td>
									{payment.method_used}
								</td>
								<td>
									{payment.payment_type}
								</td>
							</tr>
						))}
					</tbody>
				</Pahada>

			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		upcoming_summary: state.invoice.upcoming_summary,
		payments: state.payment.paidList,
		account: state.user.account
	}
}


export default withRouter(connect(mapStateToProps, { getUpcomingSummary, getPaidList })(Account))
