import React, { Component } from 'react';
import styled from 'styled-components'
import Logo from '../../images/logo_80.png'
import OrangeButton from '../../components/OrangeButton'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {login} from '../../modules/user';
import { Redirect } from 'react-router';


const Container = styled.div `
  background: #f5f5f5;
  position: fixed;
  top: 0;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`

const LoginCard = styled.div `
	background: white;
	height: 460px;
	width: 500px;
	border: 1px solid #ddd;
	text-align: center;
	padding: 30px;
`

const PaymentsLabel = styled.div `
	font-size: 25px;
	margin-bottom: 40px;
	opacity: .5;
`

const ImageCont = styled.div `
	height: 140px;
	padding-top: 30px;
`

const InputCont = styled.div `
	margin-bottom: 20px;
`

const Input = styled.input `
	height: 45px;
	font-size: 15px;
	/*padding-left: 15px;*/
	border: 0;
	border-bottom: 1px solid #ddd;
	width: 100%;

	&:focus{
		outline: none;
	};
`

const CTACont = styled.div `
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
`

const HelpText = styled.div `
	opacity: .5;
	font-size: 13px;
`

const LoginCTA = styled(OrangeButton) `
	width: 100px;
	line-height: 35px;
`

class Login extends Component {

	state = {
		email: '',
		password: ''
	};

	onChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	attemptLogin(){
		this.props.login({email: this.state.email, password: this.state.password});
	}

	render(){

		if(this.props.isLoggedin){
			return (<Redirect to="/dashboard"/>)
		}


		return (
			<Container>
				<LoginCard>
					<ImageCont>
						<img src={Logo} alt="Logo" />
					</ImageCont>
					<PaymentsLabel>
						Inneed Accounting
					</PaymentsLabel>
					<InputCont>
						<Input placeholder="Email" name="email"  onChange={(e) => this.onChange(e)}/>
					</InputCont>
					<InputCont>
						<Input  placeholder="Password" name="password" type="password" onChange={(e) => this.onChange(e)}/>
					</InputCont>
					<InputCont>
						<CTACont>
							<HelpText>
								<i> Use Inneed Login Credentials</i>
							</HelpText>
							<LoginCTA onClick={() => this.attemptLogin()}>
								Login
							</LoginCTA>
						</CTACont>
					</InputCont>
				</LoginCard>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoggedin : state.user.isLoggedin
	}
}


export default withRouter(connect(mapStateToProps, {login})(Login))
