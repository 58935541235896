import styled from 'styled-components'

const Button = styled.button `
	background: #f29000;
	color: white;
	border: none;
	box-shadow: none;
	cursor: pointer;
	&:focus{
		outline: none;
	};
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 5px;
	line-height: 30px;
`

export default Button