import React, { Component } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPaymentDetail, executeCheque, executeBankwire, executeGateway } from '../../modules/payment'

const BackDrop = styled.div `
	position: fixed;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #f5f5f5;
`

const Container = styled.div `
	width: 450px;
	height: 535px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: fixed;
	border: 1px solid #ddd;
	background: white;
`

const Content = styled.div `
	width: 100%;
	height: 100%;
	position: relative;
	text-align: center;
`

const Avatar = styled.div `
	height: 150px;
	width: 150px;
	text-transform: uppercase;
	background: #0095ff;
	color: white;
	margin: 0px auto;
	position: relative;
	font-size: 40px;
	top: -75px;
	border-radius: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Info = styled.div `
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
	top: -50px;
`

const Amount = styled.div `
	font-size: 28px;
	color: #533686;
`

const Name = styled.div `
	font-size: 20px;
	color: #533686;
	margin-top: 10px;
	margin-bottom: 30px;
`

const Reference = styled.input `
	width: 100%;
	height: 50px;
	border: none;
	border-bottom: 1px solid #aaa;
	padding-left: 20px;

	&:focus {
		outline: none;
	}
`

const Method = styled.div `
	margin-top: 15px;
	background: #e5e5e5;
	line-height: 55px;
	transition: .2s all;
	opacity: .7;
	cursor: pointer;

	&:hover{
		transform: scale(1.05);
		opacity: 1;
	}
`

const Note = styled.div `
	font-size: 15px;
	background-color: #ffde00;
	margin-top: 15px;
	padding: 5px;
	text-align: center;
	border-radius:5px;
`

const CCAvenueContainer = styled.div `
	display: inline-block;
	position: fixed;
	top: 0;
	left: 0;

	iframe{
		border: 0;
	}
`

class Payment extends Component {

	constructor(props){
		super(props)

		this.payuform_ref = React.createRef()
	}

	componentDidMount(){
		
		this.props.getPaymentDetail(this.props.match.params.id_payment)

		window.addEventListener("message", event =>  {
			if(event.data && event.data.PaymentGateway && event.data.PaymentGateway.status == "SUCCESS"){
				this.props.executeGateway(this.props.detail._id, this.state.ui_state)
				this.setState({
					ui_state: 'CHOOSE_PAYMENT_OPTION'
				})
			}
		});

		window.addEventListener("message", event =>  {
			if(event.data && event.data.payment && event.data.payment.status == "FAIL"){
				this.setState({
					ui_state: 'CHOOSE_PAYMENT_OPTION'
				})
			}
		});
	}

	componentDidUpdate(prevProps){
		if(this.props.paymentSuccess){
			if(window.opener){
				window.opener.postMessage({payment:{ status: "SUCCESS", type: this.props.paymentType}}, '*')
			}

			if(window.parent){
				window.parent.postMessage({payment:{ status: "SUCCESS", type: this.props.paymentType}}, '*')
			}
			
			setTimeout(() => {
				window.self.close()
			})
		}
	}

	state = {
		ui_state: 'CHOOSE_PAYMENT_OPTION',
		reference: ''
	}

	ccavenueScreen(){
		const { detail, reqExecute } = this.props;
		const { MERCHANT_ID, ENC_REQUEST, ACCESS_CODE, URL } = detail.ccavenue_params;
		

		// return (
		// 	<CCAvenueContainer>
		// 		<iframe 
		// 			width="450px" height="460px" 
		// 			src={`${URL}/transaction/transaction.do?command=initiateTransaction&merchant_id=${MERCHANT_ID}&encRequest=${ENC_REQUEST}&access_code=${ACCESS_CODE}`}>
		// 		</iframe>
		// 	</CCAvenueContainer>
		// )

		return (
			<CCAvenueContainer>
				<iframe 
		 			width="450px" height="460px" 
		 			src={`/payment/success?reference=DUMMYSUCCESS`}>
		 		</iframe>
			</CCAvenueContainer>
		)
	}

	payuScreen(){
		return (
			<div> PAY U </div>
		)
	}

	activateCCAvenue(){
		this.setState({
			ui_state: 'CCAVENUE'
		})
	}

	activatePayU(){
		this.payuform_ref.current.submit()
	}

	handleChange(event) {
		this.setState({reference: event.target.value});
	}

	paymentOptions(){
		const {detail, reqExecute} = this.props;
		const {payu_params} = detail;
		const { reference } = this.state;

		var name_fmt = '';

		if(detail && detail.paid_to){
			const name = detail.paid_to.name;
			name_fmt = name.split(" ")[0][0] + name.split(" ")[1][0];	
		}

		return (
			<Content> 
				<Avatar>
					{name_fmt}
				</Avatar>

				{!reqExecute ? (
						<Info>
							{payu_params && (
								<form action={payu_params.URL} method="post" id="payu_form" name="payu_form" ref={this.payuform_ref}>
									<input type="hidden" name="key" value={payu_params.DATA.key} />
									<input type="hidden" name="txnid" value={payu_params.DATA.txnid} />
									<input type="hidden" name="amount" value={payu_params.DATA.amount} />
									<input type="hidden" name="productinfo" value={payu_params.DATA.productinfo} />
									<input type="hidden" name="firstname" value={payu_params.DATA.firstname} />
									<input type="hidden" name="lastname" value={payu_params.DATA.lastname} />
									<input type="hidden" name="zipcode" value={payu_params.DATA.zipcode} />
									<input type="hidden" name="email" value={payu_params.DATA.email} />
									<input type="hidden" name="phone" value={payu_params.DATA.phone} />
									<input type="hidden" name="surl" value={payu_params.DATA.surl} />
									<input type="hidden" name="furl" value={payu_params.DATA.furl} />
									<input type="hidden" name="curl" value={payu_params.DATA.curl} />
									<input type="hidden" name="hash" value={payu_params.DATA.hash} />
									<input type="hidden" name="pg" value={payu_params.DATA.pg}/>
								</form>	
							)}

							<Amount>
								₹ {detail ? detail.amount : '-'}
							</Amount>
							<Name>
								{detail.paid_to ? detail.paid_to.name : ''}
							</Name>
							{/*<div>
								<Reference type="text" placeholder="Transaction Reference" value={reference} onChange={(event) => this.handleChange(event)}/>
							</div>*/}
							<Method onClick={() => this.props.executeBankwire(this.props.match.params.id_payment, reference)}>
								Pay By Bank Wire
							</Method>
							<Method onClick={() => this.props.executeCheque(this.props.match.params.id_payment, reference)}>
								Pay By Cheque
							</Method>
							{/*<Method onClick={() => this.activateCCAvenue()} >
								Pay Using CCAvenue
							</Method>*/}
							<Method onClick={() => this.activatePayU()} >
								Pay Using Credit or Debit Card
							</Method>
							<Note>
								Note : For Cheque Payment/Wire transfers Purchase Order shall be processed for shipment and delivery only after payment is received in full.
							</Note>
						</Info>
					) : (
						<div>
							Executing...
						</div>
					)
				}	
			</Content>
		)
	}

	render(){
		const {detail, reqExecute} = this.props;
		const {ui_state} = this.state;
		return (
			<BackDrop>
				<Container>
					{(ui_state == 'CHOOSE_PAYMENT_OPTION') && this.paymentOptions()}
					{(ui_state == 'CCAVENUE') && this.ccavenueScreen()}
					{(ui_state == 'PAYU') && this.payuScreen()}
				</Container>
			</BackDrop>
		)
	}
}

const mapStateToProps = state => {
	return {
		detail: state.payment.detail,
		reqExecute: state.payment.reqExecute,
		paymentSuccess: state.payment.paymentSuccess,
		paymentType: state.payment.paymentType
	}
}


export default withRouter(connect(mapStateToProps, {getPaymentDetail, executeCheque, executeBankwire, executeGateway })(Payment))
